export default {
  data () {
    return {
      // 数据查询接口
      queryUrl: '',
      // 数据保存接口
      saveUrl: '',
      // 数据创建接口
      createNewUrl: '',
      // 数据删除接口
      deleteUrl: '',
      // 根据Id获取数据
      getAllUrl: '',
      getByIdUrl: '',
      // 额外的请求参数
      otherParams: {},
      queryCondition: {},
      tableLoading: false,
      pageShow: false,
      // 表格数据
      tableData: [],
      // 每页数据量选项
      pageSizes: [10, 25, 50, 100],
      // 每页数据条数
      pageSize: 25,
      // 当前页码
      pageIndex: 1,
      // 数据总数
      total: 0,
      // 多选后的数据
      selection: [],
      addDialogVisible: false,
      editDialogVisible: false,
      // baseForm中的内容是否禁用编辑
      baseFormDisabled: false,
      // 当前行数据
      currentRow: {},
      detailVisible: false,
      // 附件列表
      attachmentIds: [],
      attachments: [],
      attachmentVisible: false,
      // 查询条件
      conditions: []
    }
  },

  methods: {
    getTableDataMixin () {
      /**
       * 获取表格的数据,请求数据格式和响应的数据格式与后端约定
       * @param {String}
       */
      return new Promise(resolve => {
        this.tableLoading = true
        this.$request.post(
          {
            url: this.queryUrl,
            data: {
              size: this.pageSize,
              current: this.pageIndex,
              data: this.otherParams,
              queries: this.conditions,
              ...this.queryCondition
            }
          }).then(data => {
          // 返回的数据格式和后端约定
          if (data?.code === 1000) {
            this.tableData = data.data.records
            this.total = data.data.total
            resolve()
          }
        }).finally(() => {
          this.tableLoading = false
          this.pageShow = true
        })
      })
    },

    refreshDataMixin (handler = this.getTableDataMixin) {
      /**
       * 刷新数据,请求数据前需要在params参数中重置条件
       * return undefined
       */
      this.pageIndex = 1
      // 重置高级查询条件
      this.conditions = []
      handler().then(() => {
        this.$message.success(this.$t('refreshSuccess'))
      })
    },

    addOrDeleteIdFieldMixin (type) {
      /**
       * 在fields中新增或删除id字段
       * @param {String} type 操作类型，add增加，del删除
       */
      const index = this.fields.findIndex(field => field.fieldName === 'id')
      if (type === 'add' && index === -1) {
        this.fields.push(
          {
            fieldName: 'id',
            fieldType: 'invisible'
          }
        )
      } else if (type === 'del' && index !== -1) {
        this.fields.splice(index, 1)
      }
    },

    addRowDataMixin () {
      /**
       * 增加数据
       */
      // 重置fields数据
      this.fields = this.$options.data().fields
      this.addDialogVisible = true
    },

    editRowDataMixin (row) {
      /**
       * 编辑数据
       * @param {Object} row 行数据
       */
      this.currentRow = this.$_.cloneDeep(row)
      this.fields = this.$options.data().fields
      this.fields.push(
        {
          fieldName: 'id',
          fieldType: 'invisible'
        }
      )
      this.fields.forEach(field => {
        field.default = this.currentRow[field.fieldName]
      })
      this.addDialogVisible = true
    },

    viewRowDataMixin (row, needApproval = true) {
      /**
       * 预览数据，详情页
       * @param {Object} row 行数据
       */
      this.currentRow = this.$_.cloneDeep(row)
      // 设置各字段的初始值
      this.fields.forEach(category => {
        category.fields.forEach(field => {
          field.default = row[field.fieldName]
        })
      })
      this.baseFormDisabled = true
      needApproval ? this.detailVisible = true : this.addDialogVisible = true
    },

    deleteRowDataMixin (row, handler = this.getTableDataMixin) {
      /**
       * 删除一行数据
       * @param {Object} row 行数据
       * @param {Function} handler 删除成功的回调函数
       */
      this.$request.post({
        url: this.deleteUrl,
        data: [row.id]
      }).then(data => {
        if (data?.code === 1000) {
          handler(data?.message)
          this.$message.success(this.$t('dataDeleteSuccess'))
        }
      })
    },

    deleteSelectedDataMixin (url, handler = this.getTableDataMixin) {
      /**
       * 删除多选的数据
       */
      if (!this.selection.length) {
        return this.$message.error(this.$t('selectAtLeastOneData'))
      }
      this.$confirm(this.$t('deletePermanent'), this.$t('warn'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$request.post({
          url: this.deleteUrl,
          data: this.selection.map(item => item.id)
        }).then(data => {
          if (data?.code === 1000) {
            handler(data)
            this.$message.success(this.$t('dataDeleteSuccess'))
          }
        })
      })
    },
    getAllMixin (handler = null) {
      /**
       * 获取所有列表数据，没有param
       * Get all data without params
       */
      this.$request.get({
        url: this.getAllUrl

      }).then(res => {
        if (res?.code === 1000) {
          const { data } = res
          handler && handler(data)
          this.$message.success('Get data list successful!')
          // this.$message.success(this.$t('dataModifySuccess'))
        }
      })
    },
    getSingleDataByIdMixin (id, handler = null) {
      /**
       * 根据ID获取单挑数据
       * Get single data by id
       */
      this.$request.get({
        url: `${this.getByIdUrl}/${id}`
      }).then(data => {
        if (data?.code === 1000) {
          handler && handler(data, true)
          this.$message.success('Get data by id successful!')
          // this.$message.success(this.$t('dataModifySuccess'))
        } else {
          handler && handler(data.message, false)
        }
      })
    },
    deleteSingleDataByIdMixin (id, handler = null) {
      /**
       * 删除一条数据
       * @param {id} 数据 id
       * @param {Function} handler 删除成功的回调函数
       */
      const url = `${this.deleteUrl}/${id}`
      this.$request.delete(`${url}`).then(data => {
        if (data?.code === 1000) {
          // 成功的回调函数 sucess callback function
          handler && handler(data.message)
          this.$message.success(this.$t('dataDeleteSuccess'))
        } else {
          handler && handler(data.message)
        }
      })
    },
    createNewSingleDataMixin (data, handler = null) {
      /**
       * 删除一行数据
       * @param {Object} data 创建数据参数（不含id）
       * @param {Function} handler 删除成功的回调函数
       */
      this.$request.post({
        url: this.createNewUrl,
        data
      }).then(data => {
        if (data?.code === 1000) {
          handler && handler(data.message)
          this.$message.success(this.$t('dataAddSuccess'))
        }
      })
    },
    editSingleDataMixin (data, handler) {
      /*
       * 删除一行数据
       * @param {Object} data 创建数据参数（i含id）
       * @param {Function} handler 删除成功的回调函数
       */
      this.$request.put({
        url: this.saveUrl,
        data
      }).then(data => {
        if (data?.code === 1000) {
          handler && handler(data.message)
          this.$message.success(this.$t('dataModifySuccess'))
        } else {
          handler && handler(data.message)
        }
      })
    },

    onSelectionChangeMixin (selection) {
      /**
       * 监听表格多选
       * @param {Array} selection 多选后的数据
       * return undefined
       */
      this.selection = selection
    },

    handleSelectionMixin (handler, single) {
      /**
       * 多选中只能选择一条数据的操作
       * @param {Function} handler 回调函数。没有弹窗，点击操作按钮后直接执行。
       * @param {String} handler 显示弹窗的字段名。有弹窗，直接打开弹窗。
       * @param {String} single single=='single'时，需要判断selection.length==1
       */
      if (this.selection.length === 0) {
        this.$message.error(this.$t('selectDataFirst'))
        return false
      }
      if (single === 'single' && this.selection.length > 1) {
        this.$message.error(this.$t('singleSelection'))
        return false
      }
      if (typeof handler === 'function') {
        handler()
      } else {
        this.rowData = this.selection[0]
        this[handler] = true
      }
    },

    handleSizeChangeMixin (pageSize, handler = this.getTableDataMixin) {
      /**
       * 监听每页数量变化
       * @param {Number} pageSize 回调的每页数量
       * @param {Function} handler 回调函数，一般是请求table数据的函数
       */
      this.pageSize = pageSize
      this.pageIndex = 1
      handler()
    },

    handleCurrentChangeMixin (page, handler = this.getTableDataMixin) {
      /**
       * 监听当前页码变化
       * @param {Number} page 变化后的页码
       * @param {Function} handler 回调函数，一般是请求table数据的函数
       */
      this.pageIndex = page
      handler()
    },

    onSuccessMixin (response, file) {
      /**
       * 监听附件上传成功
       */
      const id = response.data ? response.data.id : 0
      if (id) {
        this.attachmentIds.push(id)
      }
    },
    beforeRemoveMixin (file) {
      /**
       * 监听文件移除
       */
      const id = file.id || file.response.data.id
      const index = this.attachmentIds.findIndex(item => item === id)
      this.attachmentIds.splice(index, 1)
    },
    viewAttachmentMixin (row) {
      /**
       * 预览附件
       */
      this.currentRow = row
      this.attachmentVisible = true
    },
    getBlockChainInfoMixin (id) {
      /**
       * 获取区块链信息
       * @param {Number} id:表单id
       */
      this.$request.get({
        url: this.$apis.getBlockChainInfo,
        params: {
          entityId: id
        }
      }).then(data => {
        const blockChainData = {}
        if (data?.code === 1000) {
          try {
            const blockdata = data.data.data.blockdata
            blockChainData.hash = blockdata.data[0].hash
            blockChainData.txid = blockdata.txid
            blockChainData.chainOnTime = blockdata.data[0].timestamp
          } catch (e) {
            blockChainData.hash = 'There is no blockchain information.'
            blockChainData.txid = 'There is no blockchain information.'
            blockChainData.chainOnTime = 'There is no blockchain information.'
          }
          // this.$showBlockchainInfo(blockChainData, this.$t('blockChainInfo'))
        }
        return blockChainData
      })
    }
  }
}
